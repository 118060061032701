import { Route, Routes } from "react-router-dom";
import AboutUsPage from "./pages/AboutUsPage";
import User from "./pages/CreateUser";
import Assets from "./pages/Dashboard/Assets";
import Layout from "./pages/Dashboard/Dashboard";
import FAQ from "./pages/Dashboard/FAQ"; // import AboutUs from "./pages/Dashboard/AboutUs";
import ListProperty from "./pages/Dashboard/ListProperty";
import MarketPlace from "./pages/Dashboard/MarketPlace";
import OrderHistory from "./pages/Dashboard/OrderHistory";
import PreviewProperty from "./pages/Dashboard/PreviewProperty";
import HelpCenter from "./pages/HelpCenter";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NoPageFound from "./pages/NoMatch";
import AddProperty from "./pages/Property/AddProperty";

import Dao from "./pages/Dao";
import MarketPlaceHome from "./pages/MarketPlaceHome";
import PreviewPropertyHome from "./pages/PreviewPropertyHome";

import {
  AdminRoutes,
  AmericanCitizenRoute,
  ManagerRoutes,
  ProtectRoutes,
  SuperAdminRoutes,
} from "./auth/ProtectedRoutes";
import {
  default as OrderBook,
  default as TransferTable,
} from "./components/TransferTable";
import BuyPropertyTokenScreen from "./pages/Dashboard/BuyPropertyTokenScreen";
import ListComment from "./pages/Dashboard/ListComment";
import ListDocument from "./pages/Dashboard/ListDocument";
import SellPropertyTokenSceen from "./pages/Dashboard/SellPropertyTokenSceen";
import Wallet from "./pages/Dashboard/Wallets";
import PropertyTypes from "./pages/PropertyTypes";

import UnauthorizedPage from "./components/UnauthorizedPage";
import AboutUsPageHome from "./pages/AboutUsPageHome";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";
import Passwordsecurity from "./pages/Dashboard/Passwordsecurity";
import Personalinfo from "./pages/Dashboard/Personalinfo";
import Request from "./pages/Dashboard/Request";
import SettingsDashboard from "./pages/Dashboard/SettingsDashboard";
import Wishlist from "./pages/Dashboard/Wishlist";
import ForgetPassword from "./pages/ForgetPassword";
import UserKyc from "./pages/UserKyc";
import UserOrderRequest from "./pages/UserOrderRequest";
import HelpCenterDashboard from "./components/HelpCenterDashboard";
import UserReserveToken from "./pages/Dashboard/UserReserveToken";
import PropertyDetails from "./pages/Property/PropertyDetails";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfServices";
import ScrollToTop from "./components/ScrollToTop";
import Accounts from "./pages/Dashboard/Accounts";
import GoogleAuth from "./components/GoogleAuth";
import FacebookAuth from "./components/FacebookAuth";
import AmericanCitizen from "./components/AmericanCitizen";
import PdfViewer from "./pages/Dashboard/PdfViewer";
import PropertyHistorical from "./pages/Dashboard/PropertyHistorical";
import VacationPage from "./pages/Dashboard/Vacation/VacationPage";
import VacationForm from "./pages/Dashboard/Vacation/VacationForm";
import OrderTabs from "./pages/Dashboard/OrderTabs";
import RateCalendar from "./pages/Dashboard/RateCalendar/RateCalendar";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<AmericanCitizenRoute />}>
          <Route path="welcome" element={<AmericanCitizen />} />
        </Route>
        <Route path="*" element={<NoPageFound />} />
        <Route path="/:ref?" element={<Home />} />
        <Route path="/pdf" element={<PdfViewer />} />
        <Route path="/login" element={<Login />} />
        <Route path="/google-auth?" element={<GoogleAuth />} />`
        <Route path="/facebook-auth?" element={<FacebookAuth />} />
        <Route path="/create" element={<User />} />
        <Route path="/aboutuspage" element={<AboutUsPageHome />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/marketplace" element={<MarketPlaceHome />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/marketplace/propdetails/:id"
          element={<PreviewPropertyHome />}
        />
        <Route element={<ManagerRoutes />}>
          <Route path="/manager" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="addproperty" element={<UnauthorizedPage />} />
            <Route path="dao" element={<Dao />} />
            <Route path="propertytype" element={<PropertyTypes />} />
            <Route path="listproperty" element={<ListProperty />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="aboutuspage" element={<AboutUsPage />} />
            <Route path="helpcenter" element={<HelpCenterDashboard />} />
            <Route
              path="property/:property_id/comment"
              element={<ListComment />}
            />
            <Route
              path="property/:property_id/historical"
              element={<PropertyHistorical />}
            />
            <Route
              path="property/:property_id/comment/:id"
              element={<ListComment />}
            />
            <Route path="property/:property_id/rc" element={<RateCalendar />} />
            <Route path="marketplace" element={<MarketPlace />} />
            <Route path="assets" element={<Assets />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="account" element={<Accounts />} />
            <Route path="vacation" element={<VacationPage />} />
            <Route path="vacation/:type/order" element={<VacationForm />} />
            <Route path="orderhistory" element={<OrderHistory />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="personalinfo" element={<Personalinfo />} />
            <Route path="passwordsecurity" element={<Passwordsecurity />} />
            <Route path="settings" element={<SettingsDashboard />} />
            <Route path="property/:id/edit" element={<UnauthorizedPage />} />
            <Route
              path="property/:property_id/document"
              element={<ListDocument />}
            />
            <Route
              path="marketplace/propdetails/:id"
              element={<PreviewProperty />}
            />
            <Route path="buy/:id" element={<BuyPropertyTokenScreen />} />
            <Route path="sell/:id" element={<SellPropertyTokenSceen />} />
            <Route path="property/transfers" element={<TransferTable />} />
            <Route path="order-book/:id" element={<OrderBook />} />
            <Route path="order/:type?" element={<OrderTabs />} />
            <Route path="withdraw" element={<Request />} />{" "}
          </Route>
        </Route>
        <Route element={<AdminRoutes />}>
          <Route
            path="property/:property_id/historical"
            element={<PropertyHistorical />}
          />
          <Route path="/admin" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="addproperty" element={<AddProperty />} />
            <Route path="dao" element={<Dao />} />
            <Route path="propertytype" element={<PropertyTypes />} />
            <Route path="/admin/user/kyc" exact element={<UserKyc />} />
            <Route path="listproperty" element={<ListProperty />} />
            <Route path="aboutuspage" element={<AboutUsPage />} />
            <Route path="helpcenter" element={<HelpCenterDashboard />} />
            <Route
              path="property/:property_id/comment"
              element={<ListComment />}
            />
            <Route path="property/:property_id/rc" element={<RateCalendar />} />
            <Route
              path="property/:property_id/comment/:id"
              element={<ListComment />}
            />
            <Route path="marketplace" element={<MarketPlace />} />
            <Route path="assets" element={<Assets />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="account" element={<Accounts />} />
            <Route path="vacation" element={<VacationPage />} />
            <Route path="vacation/:type/order" element={<VacationForm />} />
            <Route path="orderhistory" element={<OrderHistory />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="personalinfo" element={<Personalinfo />} />
            <Route path="passwordsecurity" element={<Passwordsecurity />} />
            <Route path="settings" element={<SettingsDashboard />} />
            <Route path="property/:id/edit" element={<AddProperty />} />
            <Route
              path="property/:property_id/document"
              element={<ListDocument />}
            />
            <Route
              path="marketplace/propdetails/:id"
              element={<PreviewProperty />}
            />
            <Route path="buy/:id" element={<BuyPropertyTokenScreen />} />
            <Route path="sell/:id" element={<SellPropertyTokenSceen />} />
            <Route path="property/transfers" element={<TransferTable />} />
            <Route path="order-book/:id" element={<OrderBook />} />
            <Route path="order/:type?" element={<OrderTabs />} />
            <Route path="withdraw" element={<Request />} />{" "}
            <Route path="asset-details/:id" element={<PropertyDetails />} />
          </Route>
        </Route>
        <Route element={<SuperAdminRoutes />}>
          <Route path="/superadmin" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="addproperty" element={<AddProperty />} />
            <Route path="dao" element={<Dao />} />
            <Route path="propertytype" element={<PropertyTypes />} />
            <Route path="user/kyc" exact element={<UserKyc />} />
            <Route path="listproperty" element={<ListProperty />} />
            <Route path="aboutuspage" element={<AboutUsPage />} />
            <Route path="helpcenter" element={<HelpCenterDashboard />} />
            <Route
              path="property/:property_id/comment"
              element={<ListComment />}
            />
            <Route
              path="property/:property_id/historical"
              element={<PropertyHistorical />}
            />
            <Route
              path="property/:property_id/comment/:id"
              element={<ListComment />}
            />
            <Route path="property/:property_id/rc" element={<RateCalendar />} />
            <Route path="marketplace" element={<MarketPlace />} />
            <Route path="assets" element={<Assets />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="account" element={<Accounts />} />
            <Route path="vacation" element={<VacationPage />} />
            <Route path="vacation/:type/order" element={<VacationForm />} />
            <Route path="sell-request/:id" element={<OrderTabs />} />
            <Route path="reserve-request/:id" element={<UserReserveToken />} />
            <Route path="orderhistory" element={<OrderHistory />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="personalinfo" element={<Personalinfo />} />
            <Route path="passwordsecurity" element={<Passwordsecurity />} />
            <Route path="settings" element={<SettingsDashboard />} />
            <Route path="property/:id/edit" element={<AddProperty />} />
            <Route
              path="property/:property_id/document"
              element={<ListDocument />}
            />
            <Route
              path="marketplace/propdetails/:id"
              element={<PreviewProperty />}
            />
            <Route path="buy/:id" element={<BuyPropertyTokenScreen />} />
            <Route path="sell/:id" element={<SellPropertyTokenSceen />} />
            <Route path="property/transfers" element={<TransferTable />} />
            <Route path="order-book/:id" element={<OrderBook />} />
            <Route path="order/:type?" element={<OrderTabs />} />
            <Route path="withdraw" element={<Request />} />{" "}
            <Route path="asset-details/:id" element={<PropertyDetails />} />
          </Route>
        </Route>
        <Route element={<ProtectRoutes />}>
          <Route path="/user" element={<Layout />}>
            <Route index element={<MarketPlace />} />
            <Route path="marketplace" element={<MarketPlace />} />
            <Route path="assets" element={<Assets />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="account" element={<Accounts />} />
            <Route path="vacation" element={<VacationPage />} />
            <Route path="vacation/:type/order" element={<VacationForm />} />

            {/*<Route path="settings" element={<Settings />} />*/}
            <Route path="orderhistory" element={<OrderHistory />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="personalinfo" element={<Personalinfo />} />
            <Route path="passwordsecurity" element={<Passwordsecurity />} />
            <Route path="settings" element={<SettingsDashboard />} />
            <Route path="property/:id/edit" element={<AddProperty />} />
            <Route path="asset-details/:id" element={<PropertyDetails />} />

            <Route
              path="property/:property_id/document"
              element={<ListDocument />}
            />
            <Route path="propdetails/:id" element={<PreviewProperty />} />
            <Route
              path="marketplace/propdetails/:id"
              element={<PreviewProperty />}
            />
            <Route path="buy/:id" element={<BuyPropertyTokenScreen />} />
            <Route path="sell/:id" element={<SellPropertyTokenSceen />} />
            <Route path="property/transfers" element={<TransferTable />} />
            <Route path="order-book/:id" element={<OrderBook />} />
            <Route path="order/:type?" element={<OrderTabs />} />
            <Route path="withdraw" element={<Request />} />

            <Route path="aboutuspage" element={<AboutUsPage />} />
            <Route path="helpcenter" element={<HelpCenterDashboard />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
